import React from "react";
import { AppointmentInfo } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { isEligibleForVirtualInstantAppointment } from "../../helpers/utils";
import { secondsToMinutes } from "date-fns";
import { LinkCard } from "@amzn/stencil-react-components/card";
import { HVH_COLORS } from "../../config/palette";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { IconExternalLink } from "@amzn/stencil-react-components/icons";
import { useChecklistArb } from "../../hooks/use-arb";
import { useUrlBuilder } from "../../hooks/useUrlBuilder.hook";
import { useGetInstantAppointmentEligibility } from "../../hooks/apis/useGetInstantAppointmentEligibility";
import { InstantAppointmentEligibleStatus } from "../../utility/enums/common";

export const InstantAppointmentBanner = ({
  appointmentV2Details,
  applicationId,
}: {
  appointmentV2Details: AppointmentInfo;
  applicationId: string;
}) => {
  const bundle = useChecklistArb();
  const { instantAppointmentURL } = useUrlBuilder();
  const instantAppointmentEligibility = useGetInstantAppointmentEligibility();

  if (
    !isEligibleForVirtualInstantAppointment({
      nheDetails: appointmentV2Details,
      applicationId,
      instantAppointmentEligibility,
    }) ||
    // checked in isEligibleForVirtualInstantAppointment already, however, ts can't compile it unless we specific here
    instantAppointmentEligibility?.status !== InstantAppointmentEligibleStatus.ELIGIBLE
  ) {
    return null;
  }

  const instantAppointmentWaitTimeMin = secondsToMinutes(instantAppointmentEligibility.estimatedWaitTimeSecondsMinimum);
  const instantAppointmentWaitTimeMax = secondsToMinutes(instantAppointmentEligibility.estimatedWaitTimeSecondsMaximum);

  let instantAppointmentWaitTimeMsg;

  if (instantAppointmentWaitTimeMin === instantAppointmentWaitTimeMax || instantAppointmentWaitTimeMin === 0) {
    instantAppointmentWaitTimeMsg = bundle.formatMessage(
      "Checklist-Appointment-Instant-Appointment-Estimated-Wait-Time",
      {
        number: instantAppointmentWaitTimeMax,
      }
    );
  } else {
    instantAppointmentWaitTimeMsg = bundle.formatMessage(
      "Checklist-Appointment-Instant-Appointment-Estimated-Wait-Time-Range",
      {
        number1: instantAppointmentWaitTimeMin,
        number2: instantAppointmentWaitTimeMax,
      }
    );
  }

  return (
    <LinkCard
      backgroundColor={HVH_COLORS.PRIMARY_90}
      style={{ borderRadius: 8, maxWidth: "90%" }}
      data-testid="instant-appointment-banner"
      href={instantAppointmentURL}
      target={"_blank"}
    >
      <Col gridGap="1rem">
        <Row>
          <Text color={HVH_COLORS.NEUTRAL_0} fontSize="T200">
            {bundle.formatMessage("Checklist-Appointment-Instant-Appointment-Banner-Title")}
          </Text>
          <IconExternalLink color={HVH_COLORS.NEUTRAL_0} />
        </Row>
        <Text color={HVH_COLORS.PRIMARY_20} fontSize="T100">
          {instantAppointmentWaitTimeMsg}
        </Text>
      </Col>
    </LinkCard>
  );
};
